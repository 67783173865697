import "./conveyancing-page.styles.css";
import "../areas-of-practise.styles.css";

import AreaOfPractiseHeader from "../../../components/area-of-practise-header/area-of-practise-header.component";
import AreaOfPractiseSidebar from "../../../components/area-of-practise-sidebar/area-of-practise-sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";

const ConveyancingPage = () => (
  <div className="areas-of-practise-view min-view">
    <Navbar />
    <div className="conveyancing-head">
      <AreaOfPractiseHeader title="Conveyancing" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-12">
          <p className="areas-of-practise-title">Conveyancing</p>
          <p>• Buying</p>
          <p>• Selling</p>
          <p>• Re-Mortgaging</p>
          <p>• Residential Property Transactions</p>
          <p>• Transfer of land and farms from parent to child</p>
          <p>Grainne Dolan &amp; Co. Solicitors have extensive experience in the above areas and will be happy to guide you through the process whether you are buying or selling.</p>
        </div>
        <div className="col-md-1 col-12"></div>
        <div className="col-md-3 col-12">
          <AreaOfPractiseSidebar />
        </div>
      </div>
    </div>
  </div>
);

export default ConveyancingPage;
