import "./navbar-white.styles.css";

import Logo from "../../assets/logo-white.png";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavbarWhite = () => (
  <div className="light-nav">
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
      <Link to="/" className="navbar-brand">
        <Link to="/">
          <img src={Logo} className="brand-img" style={{ width: "250px" }} alt="Logo" />
        </Link>
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <HashLink to="/about" className="nav-link">
              About Me
            </HashLink>
          </li>
          <li className="nav-item dropdown">
            <div className="dropdown">
              <div className="nav-link">Areas Of Practice</div>
              <div className="dropdown-content">
                <Link to="/personal-injury">Personal Injury</Link>
                <Link to="/wills">Wills</Link>
                <Link to="/probate">Probate</Link>
                <Link to="/conveyancing">Conveyancing</Link>
                <Link to="/family-law">Family Law</Link>
                <Link to="/employment-law">Employment Law</Link>
              </div>
            </div>
          </li>
          <li className="nav-item nav-contact">
            <Link to="/contact" className="nav-link">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

export default NavbarWhite;
