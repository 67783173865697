import "./wills-page.styles.css";
import "../areas-of-practise.styles.css";

import AreaOfPractiseHeader from "../../../components/area-of-practise-header/area-of-practise-header.component";
import AreaOfPractiseSidebar from "../../../components/area-of-practise-sidebar/area-of-practise-sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";

const WillsPage = () => (
  <div className="areas-of-practise-view min-view">
    <Navbar />
    <div className="wills-head">
      <AreaOfPractiseHeader title="Wills" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-12">
          <p className="areas-of-practise-title">Wills</p>
          <p>A Will is a document that sets out how you wish your possessions to be distributed after your death. A Will can also deal with how your minor children will be cared for in the event of an untimely death.</p>
          <p>Therefore it is very important to take the time to make a Will no matter what age you are. This will give you peace of mind and ensure your affairs are in order.</p>
          <p>It is essential that you take legal advice when preparing a Will to ensure that it is validly executed. If you die intestate (without a Will) your estate will be distributed in accordance with the laws of the State</p>
          <p>Here at Grainne Dolan &amp; Co. Solicitors we have considerable years experience in drafting Wills for every circumstance. We also keep up to date with changes in tax laws to ensure that your Will is drafted in the most tax efficient manner as possible in consultation with your Accountant if necessary.</p>
        </div>
        <div className="col-md-1 col-12"></div>
        <div className="col-md-3 col-12">
          <AreaOfPractiseSidebar />
        </div>
      </div>
    </div>
  </div>
);

export default WillsPage;
