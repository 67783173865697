import "./personal-injury-page.styles.css";
import "../areas-of-practise.styles.css";

import AreaOfPractiseHeader from "../../../components/area-of-practise-header/area-of-practise-header.component";
import AreaOfPractiseSidebar from "../../../components/area-of-practise-sidebar/area-of-practise-sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";

const PersonalInjuryPage = () => (
  <div className="areas-of-practise-view min-view">
    <Navbar />
    <div className="personal-injury-head">
      <AreaOfPractiseHeader title="Personal Injury" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-12">
          <p className="areas-of-practise-title">Personal Injury</p>
          <p>Grainne Dolan &amp; Co. Solicitors is a specialist personal injury firm with considerable experience in motor accident, work place accidents and medical negligence cases. At Grainne Dolan &amp; Co. Solicitors we understand that being involved in an incident and instituting a claim can be a traumatic experience for some people and we aim to take the stress out of the experience. We deal with all clients with empathy and compassion. At Grainne Dolan &amp; Co. Solicitors we have the knowledge and expertise to engage the most appropriate expert advisors depending on the circumstances of your claim.</p>
          <p>
            <b>Note:</b> In contentious business a solicitor may not calculate fees or other charges as a percentage or proportion of any award or settlement.
          </p>
        </div>
        <div className="col-md-1 col-12"></div>
        <div className="col-md-3 col-12">
          <AreaOfPractiseSidebar />
        </div>
      </div>
    </div>
  </div>
);

export default PersonalInjuryPage;
