import "./about-page.styles.css";

import Navbar from "../../components/navbar/navbar.component";

import AboutImage from "../../assets/about.png";

const AboutPage = () => (
  <div className="about-page min-view">
    <Navbar />
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-12  align-self-center">
          <img className="about-image img-fluid" src={AboutImage} alt="About" />
        </div>
        <div className="col-md-6 col-12  align-self-center">
          <p className="about-headline">About Me</p>
          <p>I qualified as a Solicitor in 2004 and have over 17 years experience primarily in personal injury litigation (workplace accidents and medical negligence) and probate. I am also experienced in Family Law, Conveyancing and employment issues.</p>
          <p>I am a native of Carrickmacross and I am returning to my hometown and also to the building where my family operated a successful Take Away business for over 40 years.</p>
        </div>
      </div>
      <p className="pt-md-4">I served my apprenticeship and the last 17 years working in a very busy general practice and I have built up a reputation as being a hard worker and also for dealing with clients with empathy and compassion.</p>
      <p>I have represented clients at Circuit Court and High Court level.</p>
      <p>I live in Killanny with my husband Marc and three children.</p>
    </div>
  </div>
);

export default AboutPage;
