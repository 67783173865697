import "./home-page.styles.css";

import HomeLanding from "../../components/home-landing/home-landing.component";
import HomeAbout from "../../components/home-about/home-about.component";
import HomeAreaOfPractise from "../../components/home-area-of-practise/home-area-of-practise.component";

const HomePage = () => (
  <div>
    <HomeLanding />
    <HomeAbout />
    <HomeAreaOfPractise />
  </div>
);

export default HomePage;
