import "./probate-page.styles.css";
import "../areas-of-practise.styles.css";

import AreaOfPractiseHeader from "../../../components/area-of-practise-header/area-of-practise-header.component";
import AreaOfPractiseSidebar from "../../../components/area-of-practise-sidebar/area-of-practise-sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";

const ProbatePage = () => (
  <div className="areas-of-practise-view min-view">
    <Navbar />
    <div className="probate-head">
      <AreaOfPractiseHeader title="Probate" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-12">
          <p className="areas-of-practise-title">Probate</p>
          <p>If you have been appointed an executor under a Will or find yourself as the Next of Kin in an intestacy situation, it can be daunting to navigate the process of administration while dealing with the loss of a loved one. We at Grainne Dolan &amp; Co. Solicitors have considerable experience in this area and are here to guide you through the process with compassion and understanding.</p>
        </div>
        <div className="col-md-1 col-12"></div>
        <div className="col-md-3 col-12">
          <AreaOfPractiseSidebar />
        </div>
      </div>
    </div>
  </div>
);

export default ProbatePage;
