import "./area-of-practise-sidebar.styles.css";

import { Link } from "react-router-dom";

const AreaOfPractiseSidebar = () => (
  <div className="area-of-practise-sidebar">
    <p className="sidebar-title">Areas Of Practice</p>
    <Link to="/personal-injury">
      <div className="sidebar-link">Personal Injury</div>
    </Link>
    <Link to="/wills">
      <div className="sidebar-link">Wills</div>
    </Link>
    <Link to="/probate">
      <div className="sidebar-link">Probate</div>
    </Link>
    <Link to="/conveyancing">
      <div className="sidebar-link">Conveyancing</div>
    </Link>
    <Link to="/family-law">
      <div className="sidebar-link">Family Law</div>
    </Link>
    <Link to="/employment-law">
      <div className="sidebar-link">Employment Law</div>
    </Link>
  </div>
);

export default AreaOfPractiseSidebar;
