import "./footer.styles.css";

import Facebook from "../../assets/facebook.png";
import Phone from "../../assets/phone.png";
import Email from "../../assets/email.png";

const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-12">
          <p className="footer-title">Info</p>
          <p>Address: Grainne Dolan & Co Solicitors, Crosslane, Carrickmacross, Co. Monaghan, A81 X070</p>
          <p>
            Email: <a href="mailto:info@grainnedolan.com">info@grainnedolan.com</a>
          </p>
          <p>
            Mobile: <a href="tel:087 6961375">087 6961375</a>
          </p>
          <p>
            Landline: <a href="tel:042 9673759">042 9673759</a>
          </p>
        </div>
        <div className="col-md-2 col-12"></div>
        <div className="col-md-4 col-12">
          <p className="footer-title">Socials</p>
          <a href="https://www.facebook.com/dolangrainne/" target="_blank" rel="noopener noreferrer">
            <img className="social" src={Facebook} alt="Facebook" />
          </a>
          <a href="tel:0876961375">
            <img className="social" src={Phone} alt="Phone" />
          </a>
          <a href="mailto:info@grainnedolan.com">
            <img className="social" src={Email} alt="Email" />
          </a>
        </div>
      </div>
      <p className="copyright text-center mt-3">
        ©2021 | Web Desgin <a href="mailto:im.killian.smith@gmail.com">Killian Smith</a>
      </p>
    </div>
  </div>
);

export default Footer;
