import "./home-about.styles.css";

import AboutImage from "../../assets/about.png";

const HomeAbout = () => (
  <div className="home-about">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-12 align-self-center">
          <img src={AboutImage} alt="About Me" className="img-fluid about-img" />
        </div>
        <div className="col-md-6 col-12 align-self-center">
          <p className="home-about-headline pt-md-0 pt-3">Our Promise</p>
          <p>As part of our professional service to you, we promise:</p>
          <p>• To provide detailed professional impartial guidance, with an efficient, open and friendly approach.</p>
          <p>• To provide an open, upfront and transparent approach to the costs of our service.</p>
          <p>• To keep clients fully informed of progress in their cases at all times.</p>
          <p>• To respond promptly to all communications from our clients.</p>
          <p>• That all Client monies will be held strictly in accordance with Solicitors Accounts Regulations.</p>
        </div>
      </div>
    </div>
  </div>
);

export default HomeAbout;
