import "./home-landing.styles.css";

import NavbarWhite from "../../components/navbar-white/navbar-white.component";

import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const HomeLanding = () => (
  <div className="home-landing">
    <NavbarWhite />
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-12">
          <div className="home-landing-text mobile-center">
            <article>
              <h1 className="tagline">Experience You Can Trust</h1>

              <HashLink smooth to="/#areas-of-practice" className="btn landing-btn btn-home-practise">
                Areas Of Practice
              </HashLink>
              <Link to="/contact" className="btn landing-btn btn-home-contact">
                Contact Us
              </Link>
            </article>
          </div>
        </div>
        <div className="col-md-7 col-12"></div>
      </div>
    </div>
  </div>
);

export default HomeLanding;
