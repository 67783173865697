import "./home-area-of-practise.styles.css";

import HomeAreaOfPractiseBox from "../home-area-of-practise-box/home-area-of-practise-box.componet";

const HomeAreaOfPractise = () => (
  <div className="home-area-of-practise" id="areas-of-practice">
    <div className="container">
      <p className="areas-of-practise-header">Areas Of Practice</p>
      <div className="row">
        <HomeAreaOfPractiseBox title="Personal Injury" link="/personal-injury" />
        <HomeAreaOfPractiseBox title="Wills" link="/wills" />
        <HomeAreaOfPractiseBox title="Probate" link="/probate" />
        <HomeAreaOfPractiseBox title="Conveyancing" link="/conveyancing" />
        <HomeAreaOfPractiseBox title="Family Law" link="/family-law" />
        <HomeAreaOfPractiseBox title="Employment Law" link="/employment-law" />
      </div>
    </div>
  </div>
);

export default HomeAreaOfPractise;
